import { Commit } from 'vuex'

const state = () => ({
  users: 'ab',
})

const mutations = {
  storeData(state: any, data: object) {
    state.users = data
  },
}

const getters = {
  getUsers: (state: { users: any }) => state.users,
}

const actions = {
  getData({ commit }: { commit: Commit }, which: 'user' | 'admin') {
    if (which === 'user') {
      console.log('user')
      commit('storeData', { a: 1 })
    } else if (which === 'admin') {
      commit('storeData', { a: 2 })
    }
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
